import { Pipe, PipeTransform } from '@angular/core'
import { Sku, Product, Price } from '../models/service-response/products-response.model'
import { Order } from '../models/service-response'

@Pipe({
    name: 'pricePerUom'
})
export class PricePerUom implements PipeTransform {
    transform(sku: Sku) {
        if (sku.pricePerUom != null && sku.pricePerUom > 0) {
            let value = sku.pricePerUom
            value = (Math.round(value * 4) / 4);
            return value

        } else if( sku.multiplesOfUom != null && sku.multiplesOfUom !== 0){
            if (sku.price.offerPrice != null && sku.price.offerPrice !== 0) {
                let value = sku.price.offerPrice / sku.multiplesOfUom
                value = (Math.round(value * 4) / 4);
                return value

            } else {
                let value =  sku.price.actualPrice / sku.multiplesOfUom
                value = (Math.round(value * 4) / 4);
                return value
            }
        }
        else {
            return sku.multiplesOfUom
        }
    }
}   
@Pipe({
    name: 'productUrl'
})
export class ProductUrlPipe implements PipeTransform {
    transform(product: any) {
        return ['/shop', encodeURI(product.productShortName.trim().replace(/[,&. ]/g, '-').replace(/---/g, '-').replace(/--/g, '-').toLowerCase()), product.productId]
    }
}
@Pipe({
    name: 'orderUrl'
})
export class OrderUrlPipe implements PipeTransform {
    transform(order: Order) {
        return ['/my-orders/order-details', order.metaInfo.omsOrderId]

    }
}

@Pipe({
    name: 'productPricePipe'
})
export class ProductPricePipe implements PipeTransform {
    transform(price: Price) {
        if (price.offerPrice && price.offerPrice > 0) {
            return price.offerPrice
        } else {
            return price.actualPrice
        }
    }
}
