import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { PageFooterComponent } from './components/page-footer/page-footer.component';
import { PricePerUom, ProductUrlPipe, OrderUrlPipe, ProductPricePipe } from './pipes/pipes';
import { AlertcomponentComponent } from './components/alertcomponent/alertcomponent.component';
import { WhatsappLogoComponent } from './whatsapp-logo/whatsapp-logo.component';
import { MatTooltipModule } from '@angular/material/tooltip';

const components = [PageHeaderComponent, PageFooterComponent,WhatsappLogoComponent];
const pipes = [PricePerUom, ProductUrlPipe, OrderUrlPipe, ProductPricePipe]
@NgModule({
  declarations: [components, pipes, AlertcomponentComponent, ],
  imports: [
    CommonModule,
    RouterModule,
    MatTooltipModule
  ],
  exports: [components, pipes]
})
export class SharedModule { }
