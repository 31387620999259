import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-alertcomponent',
  templateUrl: './alertcomponent.component.html',
  styleUrls: ['./alertcomponent.component.css'],
  encapsulation:ViewEncapsulation.None
})
export class AlertcomponentComponent implements OnInit {
  data1: any;
  mytimer1: any;

  constructor( public dialogRef: MatDialogRef<AlertcomponentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.data1 = JSON.parse(this.data)
    if(this.data1.isConfirmbtn == 'false'){
      this.mytimer1 = setTimeout(() => {
      this.closeDialog(false)
      }, 5000)
    }
  }
  closeDialog(res){
    this.dialogRef.close(res)
  }
}
