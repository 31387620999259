import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { BehaviorSubject, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { environment } from './../../../environments/environment'
import { User, Credential, UserResponse } from './../models/user/user.model'
import { AppService } from 'src/app/app.service'
import { Router, ActivatedRouteSnapshot } from '@angular/router'

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    private currentUserSubject: BehaviorSubject<User>
    public currentUser: Observable<User>

    constructor(private http: HttpClient, private appService: AppService, private router: Router) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('userDetails')))
        this.currentUser = this.currentUserSubject.asObservable()
    }

    public getCurrentUserValue(): User {
        return this.currentUserSubject.value
    }

    login(username: string, password: string) {
        return this.http.post<any>(`${environment.APIEndpoint}/users/authenticate`, { username, password })
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('userDetails', JSON.stringify(user))
                    var jsonIssample = {'isVisited':'visited','message':" YOU HAVE SOME ITEMS IN YOUR CART, CLICK HERE TO CHECKOUT WHWN YOU ARE DONE"}
                    localStorage.setItem('lastProductQty',JSON.stringify(jsonIssample))
                    this.currentUser = this.currentUserSubject.asObservable()
                    this.currentUserSubject.next(user)
                }
                return user
            }))
    }
    loginOtp(credentials: Credential) {
        return this.http.post<UserResponse>(`${environment.APIEndpoint}/users/login/phone`, credentials)
            .pipe(map(userResponse => {
                if (userResponse.userDetails) {
                    localStorage.setItem('userDetails', JSON.stringify(userResponse.userDetails))
                    this.currentUserSubject.next(userResponse.userDetails)
                    this.appService.getCartSummary()
                }
                return userResponse
            }))
    }
    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('userDetails')
        localStorage.removeItem('lastProductQty')
        // localStorage.removeItem('userCart')
        this.removeToken()
        this.currentUserSubject.next(null)
        window.location.reload()
    }
    updateToken(token) {
        if (token && token.length) {
            localStorage.setItem('userToken', token)
        }
    }
    getToken() {
        let token = ''
        if (localStorage.getItem('userToken')) {
            token = localStorage.getItem('userToken')
        }
        return token
    }
    removeToken() {
        localStorage.removeItem('userToken')
    }
    isLoggedIn() {
        if (this.currentUserSubject.getValue()) {
            return true
        } else {
            return false
        }
    }
    isAdminUser() {
        let status = ''
        if (localStorage.getItem('userDetails')) {
           status=JSON.parse(localStorage.getItem('userDetails')).status
        }
        return status
    }
}
