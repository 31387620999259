import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment';
import { LocalDataStore } from './shared/models/local-data-store.model';
import { SubLocation, AcknowledgementResponse, Configuration, CategoryResponse, OrderResponse, CartUpdateResponse, PaymentMethod, PaymentMethodResponse } from './shared/models/service-response';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { ProductsResponse, ProductResponse } from './shared/models/service-response/products-response.model';
import { FilterParams, Profile, ServerCartItem } from './shared/models/service-request';
import { Observable, BehaviorSubject } from 'rxjs';
import { NewUserDetails, PhoneNumber, User, UserResponse, UserProfile, Address, UserAddressResponse } from './shared/models/user/user.model';
import { CommonAttributeResponse } from './shared/models/service-response/common-attributes.model';
import { TimeSlot, TimeSlotResponse } from './shared/models/service-response/timeslot-response.model';
import { CartDetails, CartSummary } from './shared/models/service-response/cart-summary-response.model'
import { AuthenticationService } from './shared/services/authentication.service';
import { WriteToUs } from './shared/models/service-response/write-to-us.model';
import { notifyme } from './shared/models/service-request/notifyme-request';
import { PaymentAcknowledgementResponse } from './shared/models/service-response/payment-acknowledgment-response.model';



@Injectable({
  providedIn: 'root'
})
export class AppService {
  private lang = 'en'
  private APIEndpoint = environment.APIEndpoint
  private maintenanceMode = false
  private appConfig: Configuration
  // private configLoadSubject : BehaviorSubject<boolean>
  // public configLoad : Observable<boolean>
  private cartSubject: BehaviorSubject<CartDetails>
  public cartDetails: Observable<CartDetails>
  constructor(public http: HttpClient) {
    this.cartSubject = new BehaviorSubject<CartDetails>({} as CartDetails)
    this.cartDetails = this.cartSubject.asObservable()
    // this.configLoadSubject = new BehaviorSubject<boolean>(false)
    // this.configLoad = this.configLoadSubject.asObservable()
  }
  public isMaintenanceMode() {
    return this.maintenanceMode
  }
  public getLanguage() {
    return this.lang
  }
  public getCartSummary() {
    this.http.get<CartSummary>(`${this.APIEndpoint}/users/cart/summary`).subscribe((response: CartSummary) => {
      if (response.acknowledgement.status === 'SUCCESS') {
        this.cartSubject.next(response.cart)
      }
    })
  }
  public loadConfiguration() {
    return new Promise<void>((resolve, reject) => {
      this.http.get<Configuration>(`${this.APIEndpoint}/configurations`).subscribe((response: Configuration) => {
        if (response) {
          this.setAppConfig(response)
          this.getCategories().subscribe((catResponse => {
            if (catResponse.acknowledgement.status === 'SUCCESS') {
              if (catResponse.category[0].categoryId) {
                this.appConfig.defaultCategoryId = catResponse.category[0].categoryId
                localStorage.setItem('appConfig', JSON.stringify(this.appConfig))
                this.maintenanceMode = false
                // this.configLoadSubject.next(true)
                resolve()
              } else {
                this.maintenanceMode = true
                // this.configLoadSubject.next(true)
                resolve()
              }
            } else {
              this.maintenanceMode = true
              // this.configLoadSubject.next(true)
            }
          }))
        } else {
          this.maintenanceMode = true
          // this.configLoadSubject.next(true)
        }
      }, (error: HttpErrorResponse) => {
        if ((error.status >= 500 && error.status <= 511)) {
          this.maintenanceMode = true
        }
        // this.configLoadSubject.next(true)
        resolve()
      })
    })
  }
  public getAppConfig() {
    return this.appConfig
  }
  private setAppConfig(config: Configuration) {
    this.appConfig = config
  }
  public signUp(user: NewUserDetails): Observable<AcknowledgementResponse> {
    return this.http.post<AcknowledgementResponse>(`${this.APIEndpoint}/users`, user)
  }
  public isRegistered(obj): Observable<AcknowledgementResponse> {
    return this.http.post<AcknowledgementResponse>(`${this.APIEndpoint}/users/isregistered`, obj)
  }
  public generateOtp(phoneNumber: PhoneNumber): Observable<AcknowledgementResponse> {
    return this.http.post<AcknowledgementResponse>(`${this.APIEndpoint}/users/phone/otp`, phoneNumber)
  }
  public getCartStock():Observable<UserResponse> {
    return this.http.get<UserResponse>(`${this.APIEndpoint}/cart/stock/check`)
  }
  public updateProfile(profile: UserProfile,otp:string): Observable<UserResponse> {
    return this.http.post<UserResponse>(`${this.APIEndpoint}/users/profile?&otp=${otp}`, profile)
  }
  public writeToUs(userMailDetails: WriteToUs): Observable<UserResponse> {
    return this.http.post<UserResponse>(`${this.APIEndpoint}/writetous/mail`, userMailDetails)
  }
  public getCMarketPrice(): Observable<UserResponse> {
    return this.http.get<UserResponse>(`${this.APIEndpoint}/cmarketcoffee`)
  }
  public getUser(): Observable<UserResponse> {
    return this.http.get<UserResponse>(`${this.APIEndpoint}/users/profile`)
  }
  public getUserAddress(): Observable<UserAddressResponse> {
    return this.http.get<UserAddressResponse>(`${this.APIEndpoint}/users/address`)
  }
  public getProducts(categoryId: string, filterParams?: FilterParams): Observable<ProductsResponse> {
    return this.http.get<ProductsResponse>(`${this.APIEndpoint}/categories/${categoryId}/products`, { params: filterParams.toHttpParams() })
  }
  public getOffersheet(categoryId: string, filterParams?: FilterParams){
    return this.http.get(`${this.APIEndpoint}/categories/${categoryId}/createoffersheet`, { params: filterParams.toHttpParams() ,responseType:'blob' as 'blob' })
  } 
  public getProductCard(productId: string){
    return this.http.get(`${this.APIEndpoint}/categories/any/products/productcard/${productId}`, { responseType:'blob' as 'blob' })
  }
  public getProductById(productId: string): Observable<ProductResponse> {
    return this.http.get<ProductResponse>(`${this.APIEndpoint}/categories/any/products/${productId}`)
  }
  public getCategories(): Observable<CategoryResponse> {
    return this.http.get<CategoryResponse>(`${this.APIEndpoint}/categories`)
  }
  public getOrders(): Observable<OrderResponse> {
    return this.http.get<OrderResponse>(`${this.APIEndpoint}/users/oms/orders`)
  }
  public getOrder(orderId: number): Observable<OrderResponse> {
    return this.http.get<OrderResponse>(`${this.APIEndpoint}/users/oms/orders/${orderId}`)
  }
  public getOrderPdf(orderId: number){
    return this.http.get(`${this.APIEndpoint}/users/oms/orders/printpdf/${orderId}`, { responseType:'blob' as 'blob' })
  }
  public getServerCart(): Observable<OrderResponse> {
    return this.http.get<OrderResponse>(`${this.APIEndpoint}/users/cart`)
  }
  public addToServerCart(serverCartItems: ServerCartItem[]) {
    return this.http.post<CartUpdateResponse>(`${this.APIEndpoint}/users/cart`, { cartItems: serverCartItems })
  }
  public removeFromServerCart(skuId: string) {
    return this.http.delete<CartUpdateResponse>(`${this.APIEndpoint}/users/cart/${skuId}`)
  }
  public getCommonAttributes(categoryId: string, attrs: string): Observable<CommonAttributeResponse> {
    let params = new HttpParams()
    params = params.append('attrs', attrs)
    return this.http.get<CommonAttributeResponse>(`${this.APIEndpoint}/categories/${categoryId}/commonattributes`, { params })
  }

  /*  checkout services   */

  public setOrderDeliveryAddress(userDeliveryAddress: Address): Observable<AcknowledgementResponse> {
    return this.http.post<AcknowledgementResponse>(`${this.APIEndpoint}/cart/address`, { 'addressId': userDeliveryAddress.addressId })
  }

  public setOrderDeliveryTimeSlot(deliveryTimeSlot: TimeSlot): Observable<AcknowledgementResponse> {
    return this.http.post<AcknowledgementResponse>(`${this.APIEndpoint}/timeslots`, { 'timeSlotId': deliveryTimeSlot.timeSlotId })
  }

  public getDeliveryTimeSlots(): Observable<TimeSlotResponse> {
    return this.http.get<TimeSlotResponse>(`${this.APIEndpoint}/timeslots`)
  }

  public setOrderPaymentMethod(deliveryPaymentMethod: PaymentMethod): Observable<AcknowledgementResponse> {
    return this.http.post<AcknowledgementResponse>(`${this.APIEndpoint}/paymentmethods`, { 'id': deliveryPaymentMethod.id })
  }

  public getDeliveryPaymentMethods(): Observable<PaymentMethodResponse> {
    return this.http.get<PaymentMethodResponse>(`${this.APIEndpoint}/paymentmethods`)
  }

  public placeOrder(paymentMethod: string,otp:string,secTempFlag:boolean,obj): Observable<AcknowledgementResponse> {
    return this.http.post<AcknowledgementResponse>(`${this.APIEndpoint}/users/orders?paymentMethod=${paymentMethod}&orderId=0&otp=${otp}&secTempFlag=${secTempFlag}`, obj)
  }
  public cancelOrder(orderId: string): Observable<AcknowledgementResponse> {
    return this.http.delete<AcknowledgementResponse>(`${this.APIEndpoint}/orders/${orderId}`)
  }

  public confirmPayment(paymentResponse: Object): Observable<Object> {
    return this.http.post<Object>(`${this.APIEndpoint}/users/orders/pgconfirm`, paymentResponse)
  }
  public getLocations() {
    return this.http.get(`${this.APIEndpoint}/locations`)
  }

  public generateSignupOtp(phoneNumber: PhoneNumber): Observable<AcknowledgementResponse> {
    return this.http.post<AcknowledgementResponse>(`${this.APIEndpoint}/users/signup/otp`, phoneNumber)
  }

  public SignupOtpVerification(obj):Observable<AcknowledgementResponse>{
    return this.http.post<AcknowledgementResponse>(`${this.APIEndpoint}/users/signup/otp/verification`,obj)
  }
  public updateProfileWithOutOtp(profile: UserProfile): Observable<UserResponse> {
    return this.http.post<UserResponse>(`${this.APIEndpoint}/users/profile`, profile)
  }

  public notifymeStock(obj:notifyme):Observable<AcknowledgementResponse>{
    return this.http.post<AcknowledgementResponse>(`${this.APIEndpoint}/notify`,obj)
  }

  public requestPayment(orderid,otp):Observable<PaymentAcknowledgementResponse>{
    return this.http.post<PaymentAcknowledgementResponse>(`${this.APIEndpoint}/users/orders?orderId=${orderid}&paymentMethod=PG&otp=${otp}`,{})
  }
}
