import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-footer',
  templateUrl: './page-footer.component.html',
  styleUrls: ['./page-footer.component.css']
})
export class PageFooterComponent implements OnInit {
  Year: number;

  constructor() { }

  ngOnInit() {
 this.Year=(new Date()).getFullYear()
  }

}
