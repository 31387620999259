
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'
import { AppService } from './../../app.service'
import { AuthenticationService } from '../services/authentication.service';


@Injectable()
export class AppInterceptor implements HttpInterceptor {

  constructor(private authService: AuthenticationService, private appService: AppService, public router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    req = req.clone({
      setHeaders: {
        'token': this.authService.getToken(),
        'warehouseId': this.appService.getAppConfig() ? this.appService.getAppConfig().warehouseId : '',
        'Accept-Language': this.appService.getLanguage()
      }
    })

    return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        const token = event.headers.get('token')
        if (token && token.length) {
          this.authService.updateToken(token)
        }
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        // if (err.status === 401 && (!err.url.endsWith(this.appService.authPoint) && !err.url.endsWith('/users/login'))) {
          if (err.status === 401 ) {
          // this.appService.signOut()
    this.authService.logout()
          this.router.navigate(['/sign-in'])
        }
      }
    }))
  }
}
