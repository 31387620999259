import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthenticationService } from '../../services/authentication.service';
import { User } from '../../models/user/user.model';
import { AppService } from 'src/app/app.service';
import { CartDetails } from '../../models/service-response/cart-summary-response.model';


declare var $: any
@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.css']
})
export class PageHeaderComponent implements OnInit {
  userDetails: User = {} as User
  cartDetails: CartDetails = {} as CartDetails
  cartMessage:any;
  showBadgemsg='false';
  cartMessage1: any;
  cartMessage2: any;
  mytimer:any;
  mytimer1:any;
  mytimermobile:any;
  cMarketPrice: any;
  cMarketPriceUpdateTime: any;
  constructor(private appService: AppService, private activatedRoute: ActivatedRoute, private router: Router, private authService: AuthenticationService) { }
  headerDarkRoutes = ['/account-information', '/my-orders', '/talk-to-us', '/sign-in', '/order-details', '/checkout']
  enableDarkheader = false
  ngOnInit() {
    this.getCMarket();
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      // this.headerDarkRoutes.forEach(val => {
      for (let val of this.headerDarkRoutes) {
        if (event.url.includes(val)) {
          this.enableDarkheader = true
          break
        } else {
          this.enableDarkheader = false
      $(".navigation").removeClass("product-bg");
        }
      }

      // })
      // if (this.headerDarkRoutes.includes(event.url)) {
      //   this.enableDarkheader = true
      //   console.log(event.url)
      // } else {
      //   this.enableDarkheader = false
      // }
    })
    
    	$('.navbar-collapse').on('shown.bs.collapse', function () {

 $(".navigation").addClass("product-bg-mobile");
 $(".navigation").removeClass("product-bg");
 $(".navigation").removeClass("sticky-header");
    })
    $('.navbar-collapse').on('hidden.bs.collapse', function () {
      var scroll = $(window).scrollTop();
      $(".navigation").removeClass("product-bg-mobile");
      if(scroll >=110){
        $(".navigation").addClass("sticky-header");
      }

         })
    this.authService.currentUser.subscribe((user: User) => {
      this.userDetails = user
      if (user) {
        this.appService.getCartSummary()
      }
    })
    this.appService.cartDetails.subscribe((cartDetails: CartDetails) => {
      if (cartDetails.cartItemsNumber) {
        if (localStorage.getItem('lastProductQty')) {
          this.showBadgemsg = localStorage.getItem('badgeFlag');
          this.cartMessage = JSON.parse(localStorage.getItem('lastProductQty')).message
          clearTimeout(this.mytimer)
          clearTimeout(this.mytimer1)
          if (localStorage.getItem('badgeFlag') == 'true' && JSON.parse(localStorage.getItem('lastProductQty')).isSample == false && document.getElementById('fade') != null) {
            if (document.getElementById('fade').classList.contains('AnimateSample')) {
              document.getElementById('fade').classList.remove('AnimateSample')
            }
            document.getElementById('fade').classList.add('fadeIn')
            this.mytimer = setTimeout(() => {
              if (document.getElementById('fade') != null) {
                document.getElementById('fade').classList.remove('fadeIn')
                this.mytimer1 = setTimeout(() => {
                  this.showBadgemsg = 'false'
                  localStorage.setItem('badgeFlag', 'false')
                }, 2000)
              }
            }, 6000)
          }
          else if (localStorage.getItem('badgeFlag') == 'true' && JSON.parse(localStorage.getItem('lastProductQty')).isSample == true && document.getElementById('fade') != null) {
            if (document.getElementById('fade').classList.contains('fadeIn')) {
              document.getElementById('fade').classList.remove('fadeIn')
            }
            document.getElementById('fade').classList.add('AnimateSample')
            this.mytimer = setTimeout(() => {
              if (document.getElementById('fade') != null) {
                document.getElementById('fade').classList.remove('AnimateSample')
                this.mytimer1 = setTimeout(() => {
                  this.showBadgemsg = 'false'
                  localStorage.setItem('badgeFlag', 'false')
                }, 2000)
              }
            }, 6000)
          }
          else if (JSON.parse(localStorage.getItem('lastProductQty')).isVisited == 'visited' && document.getElementById('fade') != null && localStorage.getItem('badgeFlag') == 'true' && cartDetails.cartItemsNumber != '0') {
            document.getElementById('fade').classList.add('loginAttempt')
            this.mytimer = setTimeout(() => {
              if (document.getElementById('fade') != null) {
                document.getElementById('fade').classList.remove('loginAttempt')
                this.mytimer1 = setTimeout(() => {
                  this.showBadgemsg = 'false'
                  localStorage.setItem('badgeFlag', 'false')
                }, 2000)
              }
            }, 6000)
            this.animatebanner()
          }
          else {
            this.showBadgemsg = 'false'
            localStorage.setItem('badgeFlag', 'false')
          }
        }
      }
      this.cartDetails = cartDetails
    })
  }
  getCMarket(){
    this.appService.getCMarketPrice().subscribe((response: any) => {
      this.cMarketPrice=response.cMarketPrice
    this.cMarketPriceUpdateTime=response.cMarketPriceUpdateTime
    })
  }


  logout() {
    this.authService.logout()
  }
  setBadgeFlag(){
    document.getElementById('fade').classList.remove('fadeIn')
    document.getElementById('fade').classList.remove('AnimateSample')
    document.getElementById('fade').classList.remove('loginAttempt')
    this.showBadgemsg = 'false'
    localStorage.setItem('badgeFlag','false')
  }
  animatebanner() {
    if (document.getElementById('mobileMsg') != null) {
      clearTimeout(this.mytimermobile);
      setTimeout(() => {
        document.getElementById('mobileMsg').classList.remove('fadeOut1')
        document.getElementById('mobileMsg').classList.add('fadeIn1')
      }, 2000)
      this.mytimermobile = setTimeout(() => {
        document.getElementById('mobileMsg').classList.remove('fadeIn1')
        document.getElementById('mobileMsg').classList.add('fadeOut1')
      }, 6000)
    }
  }
}
