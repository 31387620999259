import { Component, AfterViewInit, OnInit } from '@angular/core';
import { AppService } from './app.service';
import { Configuration } from './shared/models/service-response';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit, OnInit {
  title = 'alfanoos'
  showHeader = true
  showFooter = false
  isConfigLoaded = true
  constructor(public router: Router, public appService: AppService) { }
  ngOnInit() {
    // this.router.navigate([''])  //redirect other pages to homepage on browser refresh
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url !== '/maintenance') {
          if (event.url === '/404' || event.urlAfterRedirects === '/404') {
            this.showHeader = this.showFooter = false
          } else {
            if (this.appService.isMaintenanceMode()) {
              this.router.navigate(['/maintenance'])
              this.showHeader = this.showFooter = false
            } else {
              this.showHeader = this.showFooter = true
            }
          }

        } else {
          if (!this.appService.isMaintenanceMode()) {
            this.router.navigate(['/'])
            this.showHeader = this.showFooter = true
          }
        }

        window.scrollTo(0, 0)
      }
    })
  }
  ngAfterViewInit() {

  }
}

