import { Component, OnInit } from '@angular/core';

declare var $: any
@Component({
  selector: 'app-whatsapp-logo',
  templateUrl: './whatsapp-logo.component.html',
  styleUrls: ['./whatsapp-logo.component.css']
})
export class WhatsappLogoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $(function () {
      $('#WAButton').floatingWhatsApp({
          phone: '+966 551050971', //WhatsApp Business phone number
          headerTitle: 'Chat with us on WhatsApp!', //Popup Title
          popupMessage: 'Hello, how can we help you?', //Popup Message
          showPopup: false, //Enables popup display
          buttonImage: '<img src="../../../assets/images/Whatsapp-Logo.png" />', //Button Image
          // buttonImage: '<img src="https://rawcdn.githack.com/rafaelbotazini/floating-whatsapp/3d18b26d5c7d430a1ab0b664f8ca6b69014aed68/whatsapp.svg" />', //Button Image
          //headerColor: 'crimson', //Custom header color
          backgroundColor: '', //Custom background button color
          position: "right" //Position: left | right

      });
  });
  }

}
