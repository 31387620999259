import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { AuthGuard } from './shared/guards/auth.guard'
import { NotFoundComponent } from './pages/not-found/not-found.component'
import { MaintenanceComponent } from './pages/maintenance/maintenance.component'
import { AuthGuardSignUP } from './shared/guards/auth.guards'
import { AuthuserGuard } from './shared/guards/authuser.guard'
import { AuthsGuard } from './shared/guards/auths.guard'

const routes: Routes = [
    { path: '', loadChildren: './pages/home/home.module#HomeModule', canActivate: [AuthsGuard]   },

    { path: 'home',loadChildren:  './pages/home/home.module#HomeModule', canActivate: [AuthsGuard]  },

    { path: 'contact-us', loadChildren: './pages/contact-us/contact-us.module#ContactUsModule', canActivate: [AuthsGuard]  },

    { path: 'philosophy', loadChildren:'./pages/philosophy/philosophy.module#PhilosophyModule', canActivate: [AuthsGuard]  },

    { path: 'get-started',  loadChildren:'./pages/get-started/get-started.module#GetStartedModule', canActivate: [AuthsGuard]    },

    { path: 'shop', loadChildren:'./pages/shop/shop.module#ShopModule', canActivate: [AuthsGuard]   },

    { path: 'sign-up', loadChildren: './pages/sign-up/sign-up.module#SignUpModule', canActivate: [AuthGuardSignUP] },

    { path: 'talk-to-us', loadChildren:'./pages/talk-to-us/talk-to-us.module#TalkToUsModule', canActivate: [AuthsGuard]   },

    { path: 'account-information', loadChildren:'./pages/account-information/account-information.module#AccountInformationModule', canActivate: [AuthuserGuard] },

    { path: 'my-orders', loadChildren: './pages/my-orders/my-orders.module#MyOrdersModule', canActivate: [AuthGuard] },

    { path: 'checkout', loadChildren: './pages/checkout/checkout.module#CheckoutModule' , canActivate: [AuthGuard] },

    { path: 'sign-in', loadChildren:'./pages/login/login.module#LoginModule', canActivate: [AuthGuardSignUP] },

    { path: 'maintenance', component: MaintenanceComponent },

    { path: '404', component: NotFoundComponent },

    { path: '**', redirectTo: '/404' },
 
]
@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: false })],
    exports: [RouterModule],
})

export class AppRoutingModule { }


