import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { CommonModule } from '@angular/common';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app.routing'
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AppService } from './app.service';
import { AppInterceptor } from './shared/utils/app-network-interceptor';
import { NgxPaginationModule } from 'ngx-pagination';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations'
// import { AutoCompleteModule } from '@syncfusion/ej2-angular-dropdowns';
import { DatePipe } from '@angular/common'
import { NavigationService } from './pages/login/navigation.service';
import { NgImageSliderModule } from 'ng-image-slider';
export function bootstrap(appService: AppService) {
  return () => {
    // new Promise((resolve, reject) =>  {resolve()})
    return  appService.loadConfiguration()
  }
}
@NgModule({
  imports: [
    FormsModule,
    HttpClientModule,
    BrowserModule,
    CommonModule,
    SharedModule,
    // AutoCompleteModule,
    AppRoutingModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    NoopAnimationsModule,
    NgImageSliderModule,
  ],
  declarations: [
    AppComponent,
    NotFoundComponent,
    MaintenanceComponent,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: bootstrap,
      deps: [AppService],
      multi: true,
    },DatePipe, NavigationService,
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
