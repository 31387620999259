import { Injectable } from '@angular/core'
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private service: AuthenticationService, private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    if (this.service.isLoggedIn()) {
      if(this.service.isAdminUser()=='AA'){
        console.log("try")
        this.router.navigate(['/account-information'])
      }else{
        return true
      }
    } else {
        this.router.navigate(['/sign-in'], { queryParams: { next: state.url } })
      return false
    }
  }
}
